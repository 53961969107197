<template>
  <v-btn
    v-show="showButton"
    v-scroll="onScroll"
    icon="$chevronUp"
    aria-label="to top"
    v-bind="$attrs"
    class="up"
    outlined="true"
    @click="scrollToTop"
  ></v-btn>
</template>

<script setup>
const showButton = ref(false);

function onScroll() {
  showButton.value = window.scrollY > 100;
}
function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>
